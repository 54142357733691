import './stylesheets/index.css';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import { ThemeProvider as NewThemeProvider } from '@mui/material';
import { createRoot } from 'react-dom/client';
import App from './App';
import { theme, newTheme } from './stylesheets/theme';
import { HubContextProvider } from './modules/hub/context';

createRoot(document.getElementById('root')).render(
	<React.StrictMode>
		<BrowserRouter>
			<NewThemeProvider theme={newTheme}>
				<ThemeProvider theme={theme}>
					<HubContextProvider>
						<App />
					</HubContextProvider>
				</ThemeProvider>
			</NewThemeProvider>
		</BrowserRouter>
	</React.StrictMode>,
);
